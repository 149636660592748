import {Injectable} from '@angular/core';
import {ApiService} from "./common/api.service";
import {HttpService} from "./common/http.service";
import {Router} from "@angular/router";
import {API_APPLICATION} from "../config/app.config";
import {Observable} from "rxjs";

@Injectable()
export class AuctionService extends ApiService {

  constructor(http: HttpService, _router: Router) {
    super(API_APPLICATION.AUCTION, http, _router);
  }

  cancel(id: number):Observable<void>{
    return this.put(`/cancel/${id}`)
  }
}
