import {LocalStorageHelper, LocalStorageKey} from "./local-storage.helper";
import {LANGUAGES} from "../../constant/global.constant";

export const formatMoney = (amount, showPositiveSign = true, decimalCount = 0, decimal = ".", thousands = ",", currency = 'đ') => {
  try {
    currency = LocalStorageHelper.get(LocalStorageKey.LANGUAGE) === LANGUAGES.ENGLISH.language ? ' ' + LANGUAGES.ENGLISH.currency : currency;
    if (!amount) {
      return '0' + currency;
    }

    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : (showPositiveSign ? "+" : '');

    amount = Math.abs(Number(amount) || 0).toFixed(decimalCount);
    const num = parseInt(amount, undefined);
    const i = num.toString();
    const j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount ? decimal + Math.abs(amount - num).toFixed(decimalCount).slice(2) : "") + currency;
  } catch (e) {
    return '0' + currency;
  }
};
