import {Injectable} from '@angular/core';
import {ApiService} from "./common/api.service";
import {HttpService} from "./common/http.service";
import {Router} from "@angular/router";
import {API_APPLICATION} from "../config/app.config";
import {Observable} from "rxjs";
import {TripResponse} from "../model/response/TripResponse";
import {defaultPaging, FilterRequest} from "../model/filter/FilterRequest";
import {ResponseType} from "../model/response.model";
const queryString = require('query-string');

@Injectable()
export class TripService extends ApiService {
  constructor(http: HttpService, _router: Router) {
    super(API_APPLICATION.FIXED_TRIPS, http, _router);
  }

  getByParentTrip(parentTripId: number) {
    return this.get(`/${parentTripId}/trip`);
  }

  getAll(
    filter: FilterRequest = defaultPaging
  ): Observable<ResponseType<TripResponse>> {
    return this.get(``, filter);
  }

  getById(id: number): Observable<TripResponse>{
    return this.get(`/${id}`)
  }

  getPickingUpLocations({
    language,
    pickingUpLocation,
  }: {
    pageable?: FilterRequest;
    pickingUpLocation: string;
    language: string;
  }): Observable<ResponseType<string>> {
    return this.get(`/picking-up-locations`, {
      size:100,
      page: 0,
      language,
      pickingUpLocation,
    });
  }

  getDropOffLocations({
    language,
    pickingUpLocation,
  }: {
    pickingUpLocation: string;
    language: string;
  }): Observable<ResponseType<TripResponse>> {
    return this.get(`/dropping-off-locations`, {
      size:100,
      page: 0,
      language,
      pickingUpLocation,
      droppingOffLocation: '',
    });
  }
}
