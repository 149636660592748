import {Observable} from "rxjs";

export const LocalStorageKey = {
  AUTH_USER_TOKEN: 'KlSignInUserSession',
  USER_DATA: 'KlUserData',
  ORDER_TRIP: "KlOrderTrip",
  MASTER_DATA: "KlMasterData",
  LAST_ACCESS_TIME: "KlLastAccessTime",
  EMAIL: "KlEmail",
  LANGUAGE: "language"
};

export class LocalStorageHelper {

  public static save(key, value): void {
    localStorage.setItem(key, value);
  }

  public static get(key): any {
    return localStorage.getItem(key);
  }

  public static clear(key): void {
    localStorage.removeItem(key);
  }

  public static clearAll(): void {
    localStorage.clear();
  }
}
