import {Injectable} from '@angular/core';
import {HttpService} from "./common/http.service";
import {API_APPLICATION, API_BASE_URL} from "../config/app.config";
import {UserProfileModel} from "../model/request/UserProfileRequest";
import { HttpHeaders } from '@angular/common/http';
import {ApiService} from "./common/api.service";
import {Router} from "@angular/router";


@Injectable({
  providedIn: 'root'
})
export class UserProfileService extends ApiService {

  constructor(private httpService: HttpService, _router: Router) {
    super(API_APPLICATION.BOOKING_HISTORIES, httpService, _router);
  }
  getMyInfo(){
    return this.httpService.get(API_BASE_URL + API_APPLICATION.PROFILE);
  }

  updateProfile(request: UserProfileModel) {
    return this.httpService.put(API_BASE_URL + API_APPLICATION.PROFILE, request);
  }

  getTransactions() {
    return this.httpService.get(API_BASE_URL + API_APPLICATION.PROFILE + "/transactions");
  }

  getOrders() {
    return this.httpService.get(API_BASE_URL + API_APPLICATION.PROFILE + "/orders" );
  }

  getAccountDetails(username: string) {
    return this.httpService.get(API_BASE_URL + "/accounts/user-details/" + username);
  }

  getAvatar(){
    return this.httpService.get(API_BASE_URL + API_APPLICATION.PROFILE + '/avatars', { responseType: 'blob'})
  }

  updateAvatar(file: File){
    const formData = new FormData();
    formData.append('file', file);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    const options = { headers: headers };
    return this.httpService.post(API_BASE_URL + API_APPLICATION.PROFILE + '/avatars', formData, options)
  }

  changePhoneNumber(phoneNumber: string) {
    return this.httpService.put(API_BASE_URL + API_APPLICATION.PROFILE + '/phone-number', {phoneNumber})
  }
  export() {
    return this.httpService.get(API_BASE_URL + `/profile/order/export`, {
      observe: "response",
      responseType: "blob",
    });
  }
}
