import {FilterRequest} from "../../model/filter/FilterRequest";
import {LazyLoadEvent} from "primeng/api";

export default class PagesUtils {

  public static mappingToFilterRequest(event: LazyLoadEvent = null, defaultSort: string = ''): FilterRequest {
    const sorts = [];
    if (event?.sortField) {
      const sortDirection = event.sortOrder === -1 ? "-" : "";
      sorts.push(sortDirection + event?.sortField);
    } else {
      sorts.push(defaultSort);
    }

    const filterRequest = new FilterRequest();
    filterRequest.keyword = event?.globalFilter || '';
    filterRequest.limit = event?.rows || 5;
    filterRequest.sorts = sorts;
    filterRequest.page = ((event?.first || 0) / event?.rows) || 0;
    return filterRequest;
  }
}
