import {Injectable} from '@angular/core';
import {ApiService} from "./common/api.service";
import {HttpService} from "./common/http.service";
import {Router} from "@angular/router";
import {API_APPLICATION} from "../config/app.config";
import { FilterRequest } from '../model/filter/FilterRequest';
import { Observable } from 'rxjs';
import { ResponseType } from '../model/response.model';
import { AccountOutput } from '../model/account.model';

@Injectable()
export class AccountService extends ApiService {

  constructor(http: HttpService, _router: Router) {
    super(API_APPLICATION.ACCOUNT, http, _router);
  }

  find(params: FilterRequest): Observable<ResponseType<AccountOutput>> {
    return this.get('', params);
 }
}
