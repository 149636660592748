import {Pipe, PipeTransform} from "@angular/core";
import * as moment from "moment";

@Pipe({
  name: 'kl_date'
})
export class DatePipe implements PipeTransform {

  constructor() {}

  transform(date: any, format = 'DD/MM/YYYY') {
    if (!date) {
      return '';
    }
    return moment(date).format(format);
  }
}
