import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Router} from '@angular/router';

import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {HttpStatus, NAVIGATE_ROUTING} from '../../config/app.config';
import AuthUtils from "../../@core/utils/auth.utils";

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private httpClient: HttpClient, private router: Router) {

  }

  request(method: string, url: string, options): Observable<any> {
    options = this.setOptions(options);
    return this.httpClient.request(method, url, options).pipe(
      catchError(this.handleError<any>('request'))
    );
  }

  public get(url: string, options?): Observable<any> {
    options = this.setOptions(options);
    return this.httpClient.get(url, options).pipe(
      catchError(this.handleError<any>('get'))
    );
  }

  public post(url: string, body: any, options?): Observable<any> {
    options = this.setOptions(options);

    return this.httpClient.post(url, body, options).pipe(
      catchError(this.handleError<any>('post'))
    );
  }

  public put(url: string, body: any, options?): Observable<any> {
    options = this.setOptions(options);

    return this.httpClient.put(url, body, options).pipe(
      catchError(this.handleError<any>('put'))
    );
  }

  public delete(url: string, options?): Observable<any> {
    options = this.setOptions(options);

    return this.httpClient.delete(url, options).pipe(
      catchError(this.handleError<any>('delete'))
    );
  }

  private setOptions(options?) {
    options = options || {};
    options.headers = this.getHeaders(options.headers);
    return options;
  }

  private getHeaders(headers?: HttpHeaders) {
    headers = headers || new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Module': 'KingLogi',
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Allow-Origin': '*',
    });
    const token = this.getToken();
    if (token) {
      headers = headers.set('Authorization', 'Bearer ' + token);
    }
    return headers;
  }

  private getToken(): string {
    const auth = AuthUtils.getAuthUserTokens();
    return auth ? auth.accessToken : null;
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {


      // TODO: send the error to remote logging infrastructure
      console.log(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message || error}`);


      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful HttpErrorResponse code.
        // The HttpErrorResponse body may contain clues as to what went wrong,
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);

        // Let the app keep running by returning an empty result.
        if (error.status === HttpStatus.ERR_CONNECTION_REFUSED || error.status === HttpStatus.FORBIDDEN) {
          AuthUtils.logout();
          this.forceRedirect(NAVIGATE_ROUTING.FORBIDDEN);
        } else if (error.status === HttpStatus.UNAUTHORIZED) {
          AuthUtils.logout();
          this.forceRedirect(
            NAVIGATE_ROUTING.BACK_TO_LOGIN,
            { queryParams: {error: true, errorCode: error.status}}
          );
        } else if (error.status === HttpStatus.INTERNAL_SERVER_ERROR) {
          // this.forceRedirect(
          //   NAVIGATE_ROUTING.INTERNAL_SERVER_ERROR,
          //   { queryParams: {errorCode: error.status, errorMessage: error.statusText}}
          // );
        }
      }
      // return an observable with a user-facing error message
      return throwError(error);
    };
  }

  private forceRedirect(url: string, params: any = {}) {
    this.router.navigateByUrl(url, params);
  }
}
