import {Pipe, PipeTransform} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {ORDER_STATUS} from "../../constant/global.constant";

@Pipe({
  name: 'kl_orderStatus'
})
export class OrderStatusPipe implements PipeTransform {
  constructor(
    private translate: TranslateService
  ) {
  }
  transform(status: number) {
    if (!status) {
      return '';
    }
    const keys = Object.keys(ORDER_STATUS);
    for (const key of keys) {
      if (ORDER_STATUS[key].code === status) {
        const cmsKey = ORDER_STATUS[key].cms || '';
        return this.translate.instant(cmsKey);
      }
    }
    return '';
  }
}
