import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {LANGUAGES} from "./constant/global.constant";

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

  constructor(private translate: TranslateService) {
  }

  ngOnInit(): void {
    const language = localStorage.getItem('language') || LANGUAGES.VIETNAMESE.language;
    this.translate.setDefaultLang(language);
    this.translate.use(language);
  }
}
