import {Injectable} from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {BehaviorSubject} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {PushNotificationService} from "./push-notification.service";

@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);

  constructor(private afMessaging: AngularFireMessaging,
              private pushNotificationService: PushNotificationService) {

  }

  // Enable/ Disable notification: chrome://settings/content/notifications
  // For Mozilla: go into about:config and set requireuserinteraction = false
  // Test sender: https://console.firebase.google.com/u/1/project/kinglogi/notification/compose
  requestPermission() {
    this.afMessaging.requestToken.subscribe((token) => {
        // console.log('Permission granted! Save to the server!', token);
        let osName = "Unknown OS";
        if (navigator.userAgent.indexOf("Win") !== -1) osName = "Windows";
        if (navigator.userAgent.indexOf("Mac") !== -1) osName = "Macintosh";
        if (navigator.userAgent.indexOf("Linux") !== -1) osName = "Linux";
        if (navigator.userAgent.indexOf("Android") !== -1) osName = "Android";
        if (navigator.userAgent.indexOf("like Mac") !== -1) osName = "iOS";

        const request = {
          token: token,
          deviceName: navigator.platform,
          software: navigator.appCodeName,
          osVersion: navigator.appVersion,
          osName: osName
        };
        this.pushNotificationService.subscribe(request).subscribe(res =>  {
          console.log('Saved token successfully');
        }, () => {
          console.log('Could not save token');
        });
      }, (error) => { console.error(error); },
    );
  }

  receiveMessage() {
    // console.log("receiveMessage");
    this.afMessaging.messages.subscribe(
      (payload) => {
        // console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      });
  }

  deleteToken() {
    this.afMessaging.getToken
      .pipe(mergeMap(token => this.afMessaging.deleteToken(token)))
      .subscribe(
        (token) => { console.log('Token deleted!'); },
      );
  }
}
