import {Injectable} from '@angular/core';
import {ApiService} from "./common/api.service";
import {HttpService} from "./common/http.service";
import {Router} from "@angular/router";
import {API_APPLICATION} from "../config/app.config";

@Injectable()
export class CarService extends ApiService {

  constructor(http: HttpService, _router: Router) {
    super(API_APPLICATION.CARS, http, _router);
  }

  uploadImage(id: number, files: File[]){
    const formData = new FormData();
    files.forEach(file => {
      if(file){
        formData.append('files', file);
      }
    });
    return this.postFile(`/${id}/images`, formData)
  }
}
