import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  NbMediaBreakpointsService,
  NbMenuBag,
  NbMenuItem,
  NbMenuService,
  NbSidebarService,
  NbThemeService
} from '@nebular/theme';

import {LayoutService} from '../../../@core/utils';
import {map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import AuthUtils from "../../../@core/utils/auth.utils";
import {AVATAR_IMAGE, PAGING} from "../../../constant/global.constant";
import {AuthService, NotificationService} from "../../../services";
import {MESSAGE_TYPE, NAVIGATE_ROUTING} from "../../../config/app.config";
import {Router} from "@angular/router";
import {LocalStorageHelper, LocalStorageKey} from "../../../@core/utils/local-storage.helper";
import {FilterRequest} from "../../../model/filter/FilterRequest";
import {ConfirmationService, LazyLoadEvent, MessageService} from "primeng/api";
import PagesUtils from "../../../@core/utils/pages.utils";
import {TranslateService} from "@ngx-translate/core";
import {UserProfileService} from '../../../services/user-profile.service';
import {OverlayPanel} from "primeng/overlaypanel";

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  userPictureOnly: boolean = false;

  user = {
    name: '',
    username: '',
    picture: 'assets/images/alan.png',
  };

  // https://akveo.github.io/eva-icons
  userMenu: NbMenuItem[] = [
    {icon: 'lock-outline', title: 'Đổi mật khẩu', data: {id: 'changePassword'}},
    {icon: 'log-out-outline', title: 'Đăng xuất', data: {id: 'logout'}},
  ];

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];
  currentTheme = 'default';

  maxRow = 5;
  filter: FilterRequest = {
    keyword: null,
    page: 0,
    limit: this.maxRow,
    sorts: ["-createdAt"],
  };
  rowOptions = PAGING.ROW_OPTIONS;
  loading: boolean = false;
  totalRecords: 0;
  notifications: any[] = [];
  numberOfUnreadNotification: number = 0;
  @ViewChild('notificationOverlayDialog') notificationOverlayDialog: OverlayPanel;

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private layoutService: LayoutService,
              private router: Router,
              private breakpointService: NbMediaBreakpointsService,
              private authService: AuthService,
              private notificationService: NotificationService,
              private translate: TranslateService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private userProfileService: UserProfileService,
  ) {
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.getUserInformation();

    this.bindingThemeChanged();

    this.bindingUserMenuItemClicked();

    this.countUnreadNotification();

    if (navigator && navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', this.onReceiveMsg.bind(this));
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onReceiveMsg(fcmMessage: any) {
    if (fcmMessage.data && fcmMessage.data.firebaseMessagingData && fcmMessage.data.firebaseMessagingData.notification) {
      this.countUnreadNotification();
      const notification = fcmMessage.data.firebaseMessagingData.notification || {};
      this.showMessage(MESSAGE_TYPE.INFO, notification.body, notification.title);
    }
  }

  onClickNotificationIcon($event) {
    this.notificationOverlayDialog.toggle($event);
  }

  loadCarsLazy(event: LazyLoadEvent = null) {
    this.filter = PagesUtils.mappingToFilterRequest(event, "-createdAt");
    this.getNotifications();
  }

  private countUnreadNotification() {
    this.loading = true;
    this.notificationService.get('/count').subscribe((res) => {
      this.numberOfUnreadNotification = res || 0;
      this.loading = false;
    }, err => {
      console.log('Can not get messages' + err);
      this.loading = false;
    });
  }

  private getNotifications() {
    Promise.resolve(null).then(() => this.loading = true);
    this.notificationService.get('', this.filter).subscribe((res) => {
      this.notifications = res.content || [];
      this.totalRecords = res.totalElements || 0;
      this.loading = false;
    }, err => {
      console.log('Can not get messages' + err);
      this.loading = false;
    });
  }

  private getUserInformation() {
    const authUser = AuthUtils.getLoggedInUser();
    if (authUser) {
      this.user = {
        name: authUser.user.fullName,
        username: authUser.user.username,
        picture: AVATAR_IMAGE
      };

      this.getAvatarPicture();
    }
  }

  private bindingThemeChanged() {
    const {xl} = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({name}) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  private bindingUserMenuItemClicked() {
    this.menuService.onItemClick().subscribe((item: NbMenuBag) => {
      if (item.item.data.id === 'logout') {
        this.authService.logout();
      } else if (item.item.data.id === 'changePassword') {
        LocalStorageHelper.save(LocalStorageKey.EMAIL, this.user.username);
        this.router.navigateByUrl(NAVIGATE_ROUTING.CHANGE_PASSWORD).then(() => {
          window.location.reload();
        });
      }
    });
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  markAsRead(notification) {
    this.loading = true;
    this.notificationService.put('/' + notification.id).subscribe(res => {
        notification.markAsRead = true;
        this.numberOfUnreadNotification--;
        this.showMessage(MESSAGE_TYPE.SUCCESS, this.translate.instant('MESSAGE.UPDATE_SUCCESS'));
        this.loading = false;
      }, () => {
        this.loading = false;
        this.showMessage(MESSAGE_TYPE.ERROR, this.translate.instant('MESSAGE.UPDATE_NOT_SUCCESS'));
      }
    );
  }

  markAllAsRead() {
    this.loading = true;
    this.notificationService.put('/').subscribe(res => {
        this.getNotifications();
        this.countUnreadNotification();
        this.showMessage(MESSAGE_TYPE.SUCCESS, this.translate.instant('MESSAGE.UPDATE_SUCCESS'));
        this.loading = false;
      }, () => {
        this.loading = false;
        this.showMessage(MESSAGE_TYPE.ERROR, this.translate.instant('MESSAGE.UPDATE_NOT_SUCCESS'));
      }
    );
  }

  onDelete(notification) {
    this.loading = true;
    this.notificationService.delete("/" + notification.id).subscribe(res => {
        this.getNotifications();
        this.countUnreadNotification();
        this.showMessage(MESSAGE_TYPE.SUCCESS, this.translate.instant('MESSAGE.DELETE_SUCCESS'));
        this.loading = false;
      }, () => {
        this.loading = false;
        this.showMessage(MESSAGE_TYPE.ERROR, this.translate.instant('MESSAGE.DELETE_NOT_SUCCESS'));
      }
    );
  }

  deleteAll() {
    this.confirmationService.confirm({
      message: this.translate.instant('MESSAGE.CONFIRM_DELETE'),
      accept: () => {
        this.loading = true;
        this.notificationService.delete("/").subscribe(res => {
            this.getNotifications();
            this.numberOfUnreadNotification = 0;
            this.showMessage(MESSAGE_TYPE.SUCCESS, this.translate.instant('MESSAGE.DELETE_SUCCESS'));
            this.loading = false;
          }, () => {
            this.loading = false;
            this.showMessage(MESSAGE_TYPE.ERROR, this.translate.instant('MESSAGE.DELETE_NOT_SUCCESS'));
          }
        );
      }
    });
  }

  showMessage(severity: string, detail: string, title: string = ''): void {
    this.messageService.add({severity: severity, summary: title, detail: detail});
    setTimeout(() => {
      this.messageService.clear();
    }, 3000);
  }

  private getAvatarPicture() {
    this.userProfileService.getAvatar().subscribe((data: Blob) => {
      if (data.size) {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          this.user.picture = reader.result as string;
        }, false);

        if (data) {
          reader.readAsDataURL(data);
        }
      }
    });
  }
}
