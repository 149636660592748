import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'kl-dashboard-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss']
})
export class DashoardCopyrightComponent implements OnInit {

  fullYear: number = new Date().getFullYear();

  constructor() { }

  ngOnInit(): void {
  }
}
