import {Pipe, PipeTransform} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import * as moment from "moment";

@Pipe({
  name: 'kl_dateTime'
})
export class DateTimePipe implements PipeTransform {

  constructor() {}

  transform(date: any, format = 'DD/MM/YYYY HH:mm') {
    if (!date) {
      return '';
    }
    return moment(date).format(format);
  }
}
