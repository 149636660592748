import {Pipe, PipeTransform} from "@angular/core";
import {formatMoney} from "../../@core/utils/number.utils";

@Pipe({
  name: 'kl_money'
})
export class MoneyPipe implements PipeTransform {
  transform(amount: any, showPositiveSign: boolean = true): string {
    return formatMoney(amount, showPositiveSign);
  }
}
