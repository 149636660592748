import {Injectable} from '@angular/core';
import {ApiService} from "./common/api.service";
import {HttpService} from "./common/http.service";
import {Router} from "@angular/router";
import {API_APPLICATION} from "../config/app.config";

@Injectable()
export class BankAccountService extends ApiService {

  constructor(http: HttpService, _router: Router) {
    super(API_APPLICATION.BANK_ACCOUNTS, http, _router);
  }
}
