import {Pipe, PipeTransform} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {AUCTION_STATUS} from "../../constant/global.constant";

@Pipe({
  name: 'kl_auctionStatus'
})
export class AuctionStatusPipe implements PipeTransform {
  constructor(
    private translate: TranslateService
  ) {
  }
  transform(status: number) {
    if (!status) {
      return '';
    }
    const keys = Object.keys(AUCTION_STATUS);
    for (const key of keys) {
      if (AUCTION_STATUS[key].code === status) {
        const cmsKey = AUCTION_STATUS[key].cms || '';
        return this.translate.instant(cmsKey);
      }
    }
    return '';
  }
}
