import {Injectable} from '@angular/core';
import {API_BASE_URL, HttpStatus} from '../../config/app.config';
import 'rxjs-compat/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/timeout';
import 'rxjs/Observable';
import {HttpService} from './http.service';
import {throwError} from 'rxjs';
import {Router} from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import SnackbarService from "../snackbar.service";

const queryString = require('query-string');

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  baseUrl = API_BASE_URL;
  isShowSnack: boolean = false;

  constructor(urlController: string, private http: HttpService, private router: Router,private snackbarService?:SnackbarService, isShowSnack = false) {
    this.baseUrl += urlController;
    this.isShowSnack = isShowSnack
  }

  get(apiUrl: string = "", parameters: any = null, successMessage?: string, errorMessage?: string) {
    apiUrl = this.baseUrl + apiUrl;
    if (parameters) {
      apiUrl += '?' + queryString.stringify(parameters, {
        skipEmptyString: false
      });
    }

    return this.http.get(apiUrl).map(res => {
      return this.forwardData(res, successMessage);
      }).catch((error) => {
      return this.forwardError(error, errorMessage, false);
    });
  }

  post(apiUrl: string = "", body?: any, options?: any, successMessage?: string, errorMessage?: string) {
    apiUrl = this.baseUrl + apiUrl;
    body = body === undefined ? "" : JSON.stringify(body);
    return this.http.post(apiUrl, body, options).map(res => {
      return this.forwardData(res, successMessage);
      }).catch(error => {
      return this.forwardError(error, errorMessage);
    });
  }

  postFile(apiUrl: string = "", formData: FormData, successMessage?: string, errorMessage?: string) {
    apiUrl = this.baseUrl + apiUrl;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    const options = { headers: headers };
    return this.http.post(apiUrl, formData, options).map(res => {
      return this.forwardData(res, successMessage);
      }).catch(error => {
      return this.forwardError(error, errorMessage);
    });
  }

  putFile(apiUrl: string = "", formData: FormData, successMessage?: string, errorMessage?: string) {
    apiUrl = this.baseUrl + apiUrl;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    const options = { headers: headers };
    return this.http.put(apiUrl, formData, options).map(res => {
      return this.forwardData(res, successMessage);
    }).catch(error => {
      return this.forwardError(error, errorMessage);
    });
  }

  put(apiUrl: string = "", body?: any, successMessage?: string, errorMessage?: string) {
    apiUrl = this.baseUrl + apiUrl;
    body = body === undefined ? "" : JSON.stringify(body);
    return this.http.put(apiUrl, body).map(res => {
      return this.forwardData(res, successMessage);
    }).catch(error => {
      return this.forwardError(error, errorMessage);
    });
  }

  delete(apiUrl: string = "", body?: any, successMessage?: string, errorMessage?: string) {
    apiUrl = this.baseUrl + apiUrl;
    const options = {
      body: body === undefined ? "" : JSON.stringify(body)
    };
    return this.http.delete(apiUrl, options).map(res => {
      return this.forwardData(res, successMessage);
    }).catch(error => {
      return this.forwardError(error, errorMessage);
    });
  }

  private forwardData(res, successMessage?: string): any {
    return this.convertResultData(res, successMessage);
  }

  private convertResultData(res, successMessage?: string): any {
    try {
      if(this.isShowSnack && successMessage){
        this.snackbarService.success(successMessage)
      }
      return res;
    } catch (e) {
      return { result: res, status: res.status, message: res.text };
    }
  }

  forwardError(error, errorMessage?: string, show500 = true): any {
    if(error.status === HttpStatus.INTERNAL_SERVER_ERROR  && show500){
      this.snackbarService.error('INTERNAL_SERVER_ERROR')
    }else{
      if(this.isShowSnack){
        if (error.error && error.error.message) {
          this.snackbarService.error(error.error.message);
        } else {
          this.snackbarService.error(errorMessage || "error.ERROR_COMMON");
        }
      }
    }
    return throwError(error);
  }
}
