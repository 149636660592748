import {Injectable} from '@angular/core';
import {HttpService} from "./common/http.service";
import {API_APPLICATION, API_BASE_URL} from "../config/app.config";

@Injectable()
export class PaymentService {

  constructor(private httpService: HttpService) {}

  verifyPayment(request) {
    return this.httpService.put(API_BASE_URL + API_APPLICATION.PAYMENTS + "?" + request, null);
  }
}
