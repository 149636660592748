<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">KingLogi</a>
  </div>
  <!--<nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>-->
</div>

<div class="header-container">
  <a class="p-overlay-badge p-mr-5" (click)="onClickNotificationIcon($event)" title="{{'NOTIFICATION.VIEW_NOTIFICATION' | translate}}">
      <i class="pi pi-bell" style="font-size: 1.5em"></i>
      <span class="p-badge p-badge-danger" *ngIf="numberOfUnreadNotification > 0">{{numberOfUnreadNotification}}</span>
  </a>

  <p-overlayPanel #notificationOverlayDialog [showCloseIcon]="false" [style]="{width: '880px'}">

    <p-toolbar styleClass="p-mb-2" *ngIf="notifications.length">
      <ng-template pTemplate="left"></ng-template>
      <ng-template pTemplate="right">
        <button pButton pRipple type="button" icon="pi pi-check" class="p-button-secondary p-button-outlined" style="margin-right: 16px;"
                label="{{ 'BUTTON.MARK_ALL_AS_READ' | translate }}" (click)="markAllAsRead()"></button>
        <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-danger p-button-outlined"
                label="{{ 'BUTTON.DELETE_ALL' | translate }}" (click)="deleteAll()"></button>
      </ng-template>
    </p-toolbar>

    <ng-template pTemplate>
      <p-table [value]="notifications" dataKey="id" styleClass="ui-table-common" [rowHover]="true"
               [rows]="maxRow" [showCurrentPageReport]="true" [rowsPerPageOptions]="rowOptions" [loading]="loading"
               [scrollable]="true" scrollHeight="400px"
               [paginator]="true" currentPageReportTemplate="Hiển thị từ {first} đến {last} của {totalRecords}"
               [filterDelay]="500" [lazy]="true" (onLazyLoad)="loadCarsLazy($event)" [totalRecords]="totalRecords"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>{{'TABLE.STT' | translate}}</th>
            <th pSortableColumn="title">{{'NOTIFICATION.TITLE' | translate}}<p-sortIcon field="title"></p-sortIcon></th>
            <th pSortableColumn="content">{{'NOTIFICATION.CONTENT' | translate}}<p-sortIcon field="content"></p-sortIcon></th>
            <th pSortableColumn="createdAt">{{'NOTIFICATION.TIME' | translate}}<p-sortIcon field="createdAt"></p-sortIcon></th>
            <th>{{ 'TABLE.ACTION' | translate }}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-inx="rowIndex" >
          <tr>
            <td>{{inx + 1}}</td>
            <td>{{rowData.title}}</td>
            <td>{{rowData.content}}</td>
            <td>{{rowData.createdAt | kl_dateTime }}</td>
            <td class="text-center">
              <button pButton pRipple type="button" (click)="markAsRead(rowData)" *ngIf="rowData && !rowData.markAsRead"
                      title="{{ 'BUTTON.MARK_AS_READ' | translate }}"
                      icon="pi pi-check" class="p-button-secondary p-button-outlined"></button>
              <button pButton pRipple type="button" (click)="onDelete(rowData)"
                      title="{{ 'BUTTON.DELETE' | translate }}"
                      icon="pi pi-trash" class="p-button-danger p-button-outlined"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="5" class="text-center">{{ 'MESSAGE.NO_DATA' | translate }}</td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </p-overlayPanel>

  <kl-confirm-dialog></kl-confirm-dialog>

  <nb-actions size="small">
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
