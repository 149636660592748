import {Injectable} from '@angular/core';
import {HttpService} from "./common/http.service";
import {API_APPLICATION, API_BASE_URL, APP_CLIENT_ID, APP_SECRET_KEY, NAVIGATE_ROUTING} from "../config/app.config";
import {LocalStorageHelper} from "../@core/utils/local-storage.helper";


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private httpService: HttpService) {}

  login(user) {
    const body = {
      ...user,
      appClientId: APP_CLIENT_ID,
      appClientSecret: APP_SECRET_KEY
    };
    return this.httpService.post(API_BASE_URL + API_APPLICATION.SIGN_IN, body);
  }

  register(user) {
    return this.httpService.post(API_BASE_URL + API_APPLICATION.SIGN_UP, user);
  }

  refreshToken(token) {
    const body = {
      refreshToken: token
    };
    return this.httpService.post(API_BASE_URL + API_APPLICATION.REFRESH_TOKEN, body);
  }

  forgotPassword(request) {
    return this.httpService.post(API_BASE_URL + API_APPLICATION.FORGOT_PASSWORD, request);
  }

  changePassword(request) {
    return this.httpService.put(API_BASE_URL + API_APPLICATION.CHANGE_PASSWORD, request);
  }

  logout() {
    LocalStorageHelper.clearAll();
    window.location.href = NAVIGATE_ROUTING.HOME;
  }
}
