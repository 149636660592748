import {Pipe, PipeTransform} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'kl_transactionType'
})
export class TransactionTypePipe implements PipeTransform {
  constructor(
    private translate: TranslateService
  ) {
  }
  transform(type: string) {
    if (!type) {
      return '';
    }
    return this.translate.instant('PAYMENT_TYPE.' + type || '');
  }
}
