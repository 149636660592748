export class FilterRequest {
  keyword: string;
  page: number;
  limit: number;
  sorts: any;
}

export const defaultPaging:FilterRequest = {
  keyword: '',
  page: 0,
  limit: 10,
  sorts: ''
}
