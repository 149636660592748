import {Injectable} from '@angular/core';
import {HttpService} from "./common/http.service";
import {API_APPLICATION, API_BASE_URL} from "../config/app.config";
import {ApiService} from "./common/api.service";
import {Router} from "@angular/router";
import { Observable } from 'rxjs';

@Injectable()
export class OrderService extends ApiService {

  constructor(private httpService: HttpService, _router: Router) {
    super(API_APPLICATION.ORDERS, httpService, _router);
  }

  createOrderByVisitor(request) {
    return this.httpService.post(API_BASE_URL + API_APPLICATION.VISITOR_ORDERS, request);
  }

  createOrderByPartner(request) {
    return this.httpService.post(API_BASE_URL + API_APPLICATION.PARTNER_ORDERS, request);
  }

  sendInvoice(id: number): Observable<void>{
    return this.post(`/${id}/send-invoice`);
  }


}
