import jwtDecode from 'jwt-decode';
import {LocalStorageHelper, LocalStorageKey} from "./local-storage.helper";
import {User} from "../../model/User";
import {AuthUser} from "../../model/AuthUser";

export default class AuthUtils {
  /**
   * Checks if user is authenticated
   */
  public static isUserAuthenticated(): Boolean {
    const user = AuthUtils.getLoggedInUser();
    if (!user) {
      return false;
    }
    const decoded = jwtDecode(user.accessToken);
    const currentTime = Date.now() / 1000;
    const isTokenExpired = decoded.exp < currentTime;
    isTokenExpired && console.warn('Access token expired');
    return !isTokenExpired;
  }

  public static getAuthUserTokens (): AuthUser {
    const jsonUser = localStorage.getItem(LocalStorageKey.AUTH_USER_TOKEN);
    return jsonUser ? JSON.parse(jsonUser) : null;
  }
  public static getUserData(): User {
    const jsonUser = localStorage.getItem(LocalStorageKey.USER_DATA);
    return jsonUser ? JSON.parse(jsonUser) : null;
  }

  /**
   * Returns the logged in user, or null if not logged in yet.
   */
  public static getLoggedInUser(): AuthUser {
    const authUser = AuthUtils.getAuthUserTokens();
    if (authUser) {
      authUser.user = AuthUtils.getUserData();
    }
    return authUser;
  }

  /**
   * Clear all cached data
   */
  public static logout() {
    LocalStorageHelper.clearAll();
  }

  public static getUserDataFromToken(token): User {
    const decoded = jwtDecode(token);
    return decoded.sub ? JSON.parse(decoded.sub) : {};
  }
}
