import {Injectable} from '@angular/core';
import {ApiService} from "./common/api.service";
import {HttpService} from "./common/http.service";
import {Router} from "@angular/router";
import {API_APPLICATION} from "../config/app.config";

@Injectable()
export class MemberAccountService extends ApiService {

  constructor(http: HttpService, _router: Router) {
    super(API_APPLICATION.MEMBER_ACCOUNTS, http, _router);
  }

  getTransactionByAccountId(id: number) {
    return this.get('/' + id + '/transactions');
  }

  addTransaction(id: number, request: any) {
    return this.post('/' + id + '/transactions', request);
  }
}
