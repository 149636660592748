export const PAGING = {
  MAX_ROW: 10,
  MAX_LIMIT: 1000,
  ROW_OPTIONS: [5, 10, 20, 30, 50],
};

export const ACTION_MODE = {
  NONE: 0,
  ADD: 1,
  EDIT: 2,
  VIEW: 3
};

export const LANGUAGES = {
  ENGLISH : {
    label: 'English',
    language: 'en',
    currency: 'VND'
  },
  VIETNAMESE : {
    label: 'Tiếng Việt',
    language: 'vi',
    currency: 'đ'
  }
};

export const PAYMENT_TYPE = {
  VNPAY : "VNPAY",
  BANK_ACCOUNT : "BANK_ACCOUNT",
  BALANCE: "BALANCE",
  DIRECT: "DIRECT"
};

export const PAYMENT_TYPES = [
  {type: PAYMENT_TYPE.DIRECT, cms: 'PAYMENT_TYPE.DIRECT'},
  {type: PAYMENT_TYPE.BALANCE, cms: 'PAYMENT_TYPE.BALANCE'},
  {type: PAYMENT_TYPE.VNPAY, cms: 'PAYMENT_TYPE.VNPAY'},
  {type: PAYMENT_TYPE.BANK_ACCOUNT, cms: 'PAYMENT_TYPE.BANK_ACCOUNT'},
];

  export const GroupFeature = {
  ALL: 'ALL',
  SYSTEM: 'SYSTEM',
  MASTER: 'MASTER',
  FIXED_TRIP: 'FIXED_TRIP',
  FLEXIBLE_TRIP: 'FLEXIBLE_TRIP',
};

export const Gender = {
  M: {
    lable_vi: 'Nam',
    lable_en: 'Male',
    picture: 'assets/images/alan.png'
  },
  F: {
    lable_vi: 'Nữ',
    lable_en: 'Female',
    picture: 'assets/images/eva.png'
  },
  O: {
    lable_vi: 'Khác',
    lable_en: 'Other',
    picture: 'assets/images/nick.png'
  },
};

export const NUMBER_OF_SEATS = [
  { name: 'Chọn xe', code: null },
  { name: '4 chỗ', code: 4 },
  { name: '5 chỗ', code: 5 },
  { name: '7 chỗ', code: 7 },
  { name: '8 chỗ', code: 8 },
  { name: '12-16 chỗ', code: 16 },
  { name: '24-30 chỗ', code: 24 },
  { name: '45 chỗ', code: 45 },
  { name: 'Xe tải', code: 1 },
  { name: 'Xe khác', code: 0 }
];

export const IMAGE_PATH = 'assets/images/kinglogi/';

export const IMAGE_NAMES = [
  '1.png',
  '2.jpg',
  '3.jpg',
  '4.jpg',
  '5.png'
];

export const DEFAULT_IMAGE_PATH = 'assets/images/default.jpg';

export const DATE_TIME_PATTERN = {
  DATE: "dd/mm/yy",
  DATE_TIME: "dd/mm/yy HH:mm",
};

export const EMAIL_TEMPLATE_TYPE = {
  FORGOT_PASSWORD: [
    {key: 'username', cms: 'FORGOT_PASSWORD.USER_NAME', placeholder: '[[${username}]]'},
    {key: 'password', cms: 'FORGOT_PASSWORD.PASSWORD', placeholder: '[[${password}]]'},
    {key: 'expiredInMinutes', cms: 'FORGOT_PASSWORD.EXPIRED_IN_MINUTES', placeholder: '[[${expiredInMinutes}]]'},
  ],
  WELCOME: [
    {key: 'fullName', cms: 'SIGN_UP.FULL_NAME', placeholder: '[[${fullName}]]'},
    {key: 'phoneNumber', cms: 'SIGN_UP.PHONE_NUMBER', placeholder: '[[${phoneNumber}]]'},
    {key: 'username', cms: 'SIGN_UP.USER_NAME', placeholder: '[[${username}]]'},
  ],
  CONTACT: [
    {key: 'fullName', cms: 'CONTACT_FORM.FULL_NAME', placeholder: '[[${fullName}]]'},
    {key: 'phoneNumber', cms: 'CONTACT_FORM.PHONE', placeholder: '[[${phoneNumber}]]'},
    {key: 'email', cms: 'CONTACT_FORM.EMAIL', placeholder: '[[${email}]]'},
    {key: 'subject', cms: 'CONTACT_FORM.SUBJECT', placeholder: '[[${subject}]]'},
    {key: 'content', cms: 'CONTACT_FORM.CONTENT', placeholder: '[[${content}]]'},
  ],
  PAID: [
    {key: 'fullName', cms: 'ORDER.FULL_NAME', placeholder: '[[${fullName}]]'},
    {key: 'phoneNumber', cms: 'ORDER.PHONE_NUMBER', placeholder: '[[${phoneNumber}]]'},
    {key: 'email', cms: 'ORDER.EMAIL', placeholder: '[[${email}]]'},
    {key: 'orderCode', cms: 'ORDER.ORDER_CODE', placeholder: '[[${orderCode}]]'},
    {key: 'pickingUpLocation', cms: 'ORDER.PICKING_UP_LOCATION', placeholder: '[[${pickingUpLocation}]]'},
    {key: 'droppingOffLocation', cms: 'ORDER.DROPPING_OFF_LOCATION', placeholder: '[[${droppingOffLocation}]]'},
    {key: 'totalPrice', cms: 'ORDER.TOTAL_PRICE', placeholder: '[[${totalPrice}]]'},
    {key: 'numberOfSeat', cms: 'ORDER.NUMBER_OF_SEAT', placeholder: '[[${numberOfSeat}]]'},
    {key: 'note', cms: 'ORDER.NOTE', placeholder: '[[${note}]]'},
    {key: 'pickingUpDatetime', cms: 'ORDER.PICKING_UP_DATE_TIME', placeholder: '[[${pickingUpDatetime}]]'}
  ],
  UNPAID: [
    {key: 'fullName', cms: 'ORDER.FULL_NAME', placeholder: '[[${fullName}]]'},
    {key: 'phoneNumber', cms: 'ORDER.PHONE_NUMBER', placeholder: '[[${phoneNumber}]]'},
    {key: 'email', cms: 'ORDER.EMAIL', placeholder: '[[${email}]]'},
    {key: 'orderCode', cms: 'ORDER.ORDER_CODE', placeholder: '[[${orderCode}]]'},
    {key: 'pickingUpLocation', cms: 'ORDER.PICKING_UP_LOCATION', placeholder: '[[${pickingUpLocation}]]'},
    {key: 'droppingOffLocation', cms: 'ORDER.DROPPING_OFF_LOCATION', placeholder: '[[${droppingOffLocation}]]'},
    {key: 'totalPrice', cms: 'ORDER.TOTAL_PRICE', placeholder: '[[${totalPrice}]]'},
    {key: 'numberOfSeat', cms: 'ORDER.NUMBER_OF_SEAT', placeholder: '[[${numberOfSeat}]]'},
    {key: 'note', cms: 'ORDER.NOTE', placeholder: '[[${note}]]'},
    {key: 'pickingUpDatetime', cms: 'ORDER.PICKING_UP_DATE_TIME', placeholder: '[[${pickingUpDatetime}]]'}
  ],
  START_AUCTION: [
    {key: 'name', cms: 'AUCTION.NAME', placeholder: '[[${name}]]'},
    {key: 'totalPrice', cms: 'AUCTION.TOTAL_PRICE', placeholder: '[[${totalPrice}]]'},
    {key: 'description', cms: 'AUCTION.DESCRIPTION', placeholder: '[[${description}]]'},
    {key: 'startAt', cms: 'AUCTION.START_AT', placeholder: '[[${startAt}]]'},
  ],
  FINISH_AUCTION: [
    {key: 'name', cms: 'AUCTION.NAME', placeholder: '[[${name}]]'},
    {key: 'totalPrice', cms: 'AUCTION.TOTAL_PRICE', placeholder: '[[${totalPrice}]]'},
    {key: 'description', cms: 'AUCTION.DESCRIPTION', placeholder: '[[${description}]]'},
    {key: 'startAt', cms: 'AUCTION.START_AT', placeholder: '[[${startAt}]]'},
    {key: 'endAt', cms: 'AUCTION.END_AT', placeholder: '[[${endAt}]]'},
    {key: 'bidUserName', cms: 'AUCTION.BID_USERNAME', placeholder: '[[${bidUserName}]]'},
  ],
};
export const GENDER = ['M', 'F', 'O'];
export const OPERATOR = [
  {
    key: "PLUS",
    value: 0
  },
  {
    key: "SUBTRACT",
    value: 1
  }
  ];

export const COMMISSION_TYPE = [
  {
    key: "AMOUNT",
    value: 0
  },
  {
    key: "PERCENT",
    value: 1
  }
];

export const AUCTION_TYPE = [
  {
    key: "BUY",
    value: 0
  },
  {
    key: "RESERVE",
    value: 1
  }
];

export const ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  FIXED_TRIP_ADMIN: 'FIXED_TRIP_ADMIN',
  FLEXIBLE_TRIP_ADMIN: 'FLEXIBLE_TRIP_ADMIN',
  MEMBER: 'MEMBER',
  PARTNER: 'PARTNER',
  COLLABORATOR: 'COLLABORATOR',
  AGENT_ADMIN: "AGENT_ADMIN",
  SUB_AGENT_ADMIN: "SUB_AGENT_ADMIN"
} as const;

export const ACCOUNT_ROLE = Object.values(ROLES);

export const TARGET_USER = ['MEMBER', "PARTNER", "COLLABORATOR", "COLLABORATOR_GROUP"]

export const AUCTION_STATUS = {
  INIT : {
    code: 1,
    description: "Initialization",
    cms: "AUCTION.INIT"
  },
  START : {
    code: 10,
    description: "Start",
    cms: "AUCTION.START"
  },
  STOP : {
    code: 90,
    description: "Stop",
    cms: "AUCTION.STOP"
  },
  FINISH : {
    code: 100,
    description: "Finish",
    cms: "AUCTION.FINISH"
  },
};
export const ORDER_STATUS = {
  WAITING_FOR_PAY_IN : {
    code: 10,
    description: "Waiting for pay in",
    cms: "ORDER.WAITING_FOR_PAY_IN"
  },
  PAID: {
    code: 20,
    description: "Paid",
    cms: "ORDER.PAID"
  },
  REJECTED : {
    code: 40,
    description: "Rejected",
    cms: "ORDER.REJECTED"
  },
  CANCELLED : {
    code: 50,
    description: "Cancelled",
    cms: "ORDER.CANCELLED"
  },
  COMPLETED: {
    code: 60,
    description: "Completed",
    cms: "ORDER.COMPLETED"
  },
  READY_FOR_MOVING: {
    code: 21,
    description: "Ready for moving",
    cms: "ORDER.READY_FOR_MOVING"
  },
  ON_GOING: {
    code: 22,
    description: "On going",
    cms: "ORDER.ON_GOING"
  },
};

export const ORDER_STATUSES = [
  { ...ORDER_STATUS.WAITING_FOR_PAY_IN, type: 'ORDER_STATUS' },
  { ...ORDER_STATUS.PAID, type: 'PAID' },
  { ...ORDER_STATUS.REJECTED, type: 'REJECTED' },
  { ...ORDER_STATUS.COMPLETED, type: 'COMPLETED'},
  { ...ORDER_STATUS.ON_GOING, type: 'ON_GOING'},
  { ...ORDER_STATUS.READY_FOR_MOVING, type: 'READY_FOR_MOVING'}
];

export enum TRANSACTION_REASON {
  PAY_IN,
  PAYMENT_ORDER
}

export const TRIP_TYPE = {
  NONE: 0,
  FIXED_TRIP: 1,
  FLEXIBLE_TRIP: 2
};

export const AVATAR_IMAGE = 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y';

export const EMAIL_OR_PHONE_REGEX = /^(?:\d{10,11}|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})$/;

export const EMAIL_REGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
