import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'kl_limit'
})
export class LimitTextPipe implements PipeTransform {
  transform(txt: string, limit: number = 64): string {
    if (!txt || txt.length <= limit) {
      return txt;
    }
    return txt.substring(0, limit) + '...';
  }
}
