import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from 'ngx-toastr';
@Injectable()
export default class SnackbarService {
  constructor(
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {}

  error(message: string, useTranslate: boolean = true): void {
    this.toastr.error(useTranslate
      ? this.translate.instant("SERVER_MESSAGE." + message)
      : message);
  }

  success(message?: string, useTranslate: boolean = true): void {
    this.toastr.success(
      useTranslate
        ? message
          ? this.translate.instant(message)
          : this.translate.instant("MESSAGE.ADD_SUCCESS")
        : message || this.translate.instant("MESSAGE.ADD_SUCCESS")
    );
  }
}
