import {NgModule} from '@angular/core';
import {KlConfirmDialogComponent} from "./confirm-dialog.component";
import {TranslateModule} from "@ngx-translate/core";
import {ButtonModule} from 'primeng/button';
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ConfirmationService} from "primeng/api";

@NgModule({
  declarations: [
    KlConfirmDialogComponent
  ],
  imports: [
    ButtonModule,
    ConfirmDialogModule,
    TranslateModule,
  ],
  exports: [
    KlConfirmDialogComponent
  ],
  providers: [
    ConfirmationService
  ]
})
export class KlConfirmDialogModule { }
