import {Pipe, PipeTransform} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {NUMBER_OF_SEATS} from "../../constant/global.constant";

@Pipe({
  name: 'kl_numberOfSeat'
})
export class NumberOfSeatPipe implements PipeTransform {
  constructor(
    private translate: TranslateService
  ) {
  }
  transform(numberOfSeat: number) {
    if (numberOfSeat === 0) {
      return this.translate.instant('TYPE_OF_TRANSPORTATION.OTHER');
    }
    if (numberOfSeat === 1) {
      return this.translate.instant('TYPE_OF_TRANSPORTATION.TRUCK');
    }
    for (const item of NUMBER_OF_SEATS) {
      if (item.code === numberOfSeat) {
        return this.translate.instant('TYPE_OF_TRANSPORTATION.SEATER', {numberOfSeat: item.code});
      }
    }
    return '';
  }
}
