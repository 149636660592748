import { Injectable } from '@angular/core';
import { ApiService } from "./common/api.service";
import { HttpService } from "./common/http.service";
import { Router } from "@angular/router";
import { API_APPLICATION } from "../config/app.config";
import { Observable } from 'rxjs';
import { ResponseType } from '../model/response.model';
import { IPromotionInput, IPromotionOutput } from '../model/promotion.model';
import { defaultPaging, FilterRequest } from '../model/filter/FilterRequest';

@Injectable()
export class PromotionService extends ApiService {

  constructor(http: HttpService, _router: Router) {
    super(API_APPLICATION.PROMOTIONS, http, _router);
  }

  getPromotions(params: FilterRequest): Observable<ResponseType<IPromotionOutput>> {
     return this.get('', params);
  }

  create(promotion: IPromotionInput): Observable<{}> {
    return this.post('', promotion);
  }

  findById(id: number): Observable<IPromotionOutput> {
    return this.get(`/${id}`);
  }

  updateById(id: number, promotion: IPromotionInput): Observable<IPromotionOutput> {
    return this.put(`/${id}`, promotion);
  }

  findByIdAndDelete(id: number): Observable<{}> {
    return this.delete(`/${id}`);
  }


  findByUser(params: FilterRequest= defaultPaging): Observable<ResponseType<IPromotionOutput>>{
    return this.get(`/user`, params);
  }

  deleteUser(id: number, userId: number): Observable<void>{
    return this.delete(`/${id}/users/${userId}`)
  }

  reactiveUser(id: number, userId: number): Observable<IPromotionOutput>{
    return this.put(`/${id}/users/${userId}/reactive`)
  }

  findByCode(code: string): Observable<IPromotionOutput>{
    return this.get(`/code`, {code})
  }
}
