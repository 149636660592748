import {Injectable} from '@angular/core';
import {HttpService} from "./common/http.service";
import {API_APPLICATION, API_BASE_URL} from "../config/app.config";

@Injectable()
export class PushNotificationService {

  constructor(private httpService: HttpService) {}

  subscribe(request) {
    return this.httpService.post(API_BASE_URL + API_APPLICATION.PUSH_NOTIFICATIONS + "/subscribe", request);
  }
}
